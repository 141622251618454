// extracted by mini-css-extract-plugin
export var productbanner = "product-module--productbanner--55d29";
export var productcolumn = "product-module--productcolumn--54e3a";
export var productframe = "product-module--productframe--11b22";
export var productitem = "product-module--productitem--96626";
export var productlabel = "product-module--productlabel--309c3";
export var productlabel10x = "product-module--productlabel10x--99868";
export var productlabel201 = "product-module--productlabel201--b8158";
export var productlabel202 = "product-module--productlabel202--6c87f";
export var productlabel210 = "product-module--productlabel210--95437";
export var productlabel222 = "product-module--productlabel222--95aae";
export var productlabel231 = "product-module--productlabel231--39cca";
export var productlabel3xx = "product-module--productlabel3xx--2ca01";
export var productlabel402 = "product-module--productlabel402--7e0bb";
export var productlabel406 = "product-module--productlabel406--66264";
export var productlabel411 = "product-module--productlabel411--7d122";
export var productlabel421 = "product-module--productlabel421--71b9c";
export var productlabel441 = "product-module--productlabel441--64971";
export var productlabel630 = "product-module--productlabel630--b68d7";
export var productlabelinline = "product-module--productlabelinline--16359";