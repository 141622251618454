import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"

import * as styles from '../../styles/pages/product.module.css'


import image_k3xx from '../../images/product/menu/k3xx.jpg'
import image_k3xxm from '../../images/product/menu/k3xx_mobile.jpg'
import image_k402 from '../../images/product/menu/k402.jpg'
import image_k402m from '../../images/product/menu/k402_mobile.jpg'
import image_k406 from '../../images/product/menu/k406.jpg'
import image_k406m from '../../images/product/menu/k406_mobile.jpg'
import image_k411 from '../../images/product/menu/k411.jpg'
import image_k411m from '../../images/product/menu/k411_mobile.jpg'
import image_k441 from '../../images/product/menu/k441.jpg'
import image_k441m from '../../images/product/menu/k441_mobile.jpg'
import image_k421 from '../../images/product/menu/k421.jpg'
import image_k421m from '../../images/product/menu/k421_mobile.jpg'



const ProductFlooringPage = () => (
  <Layout mobileTitle={true} activePath='product/flooring' pageTitle='For Flooring'>
  	<Link to="/product/flooring/k3xx" className={styles.productbanner}>
  		<img className={styles.productitem+" fullblock-desktop-only"} src={image_k3xx} alt="K-321 K-302" />
  		<img className={styles.productitem+" fullblock-mobile-only"} src={image_k3xxm} alt="K-321 K-302" />
  	</Link>
  	<Link to="/product/flooring/k402" className={styles.productcolumn}>
  		<img className={styles.productitem+" fullblock-desktop-only"} src={image_k402} alt="K-402" />
      <img className={styles.productitem+" fullblock-mobile-only"} src={image_k402m} alt="K-402" />
  	</Link>
  	<Link to="/product/flooring/k406" className={styles.productcolumn}>
  		<img className={styles.productitem+" fullblock-desktop-only"} src={image_k406} alt="K-406" />
      <img className={styles.productitem+" fullblock-mobile-only"} src={image_k406m} alt="K-406" />
  	</Link>
  	<Link to="/product/flooring/k411" className={styles.productcolumn}>
  		<img className={styles.productitem+" fullblock-desktop-only"} src={image_k411} alt="K-411" />
      <img className={styles.productitem+" fullblock-mobile-only"} src={image_k411m} alt="K-411" />
  	</Link>
  	<Link to="/product/flooring/k441" className={styles.productcolumn}>
  		<img className={styles.productitem+" fullblock-desktop-only"} src={image_k441} alt="K-441" />
      <img className={styles.productitem+" fullblock-mobile-only"} src={image_k441m} alt="K-441" />
  	</Link>
  	<Link to="/product/flooring/k421" className={styles.productbanner}>
  		<img className={styles.productitem+" fullblock-desktop-only"} src={image_k421} alt="K-421" />
  		<img className={styles.productitem+" fullblock-mobile-only"} src={image_k421m} alt="K-421" />
  	</Link>
  </Layout>
)

export default ProductFlooringPage
